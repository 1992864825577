@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Europa';
  font-weight: bold;
  src: url('assets/fonts/Europa-Bold.ttf') format('truetype');
}

:root {
  --niche-pos-x: 0vw;
  --niche-pos-y: 0vh;
  --tensor-pos-x: 0vw;
  --tensor-pos-y: 0vh;
  --hero-desc-opacity: 0.5;
  --hero-arrow-opacity: 1;
  --hero-arrow-rotate: 0deg;
  --hero-api-translateY: 0;
}

body {
  margin: 0;
  font-family: 'Europa', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E3E3E3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 767px) {
  .header-nav-menu {
    position: fixed;
    background: #001AFF;
    width: 198px;
    height: 100vh;
    top: 0;
    right: -198px;
    color: white;
    padding-top: 96px;
    font-size: 20px;
    line-height: 20px;
    overflow: hidden;
    transition: transform 0.3s ease;
    /* Smooth transition */
    padding-left: 20px;
  }
}

.header-nav-menu.active {
  /* width: 198px; */
  transform: translateX(-198px);
  /* Move into view */
}

@media (max-width: 767px) {
  .header-nav-menu nav ul {
    gap: 40px;
  }
}

/* Hamburger Button */
.hamburger {
  padding: 0;
  width: 16px;
  height: 16px;
  border: none;
  z-index: 101;
}

.hamburger span {
  position: relative;
  display: block;
  width: 16px;
  height: 2px;
  margin-bottom: 5px;
  z-index: 1;
  background: black;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

.hamburger span:first-child {
  transform-origin: 0% 0%;
}

.hamburger span:nth-last-child(2) {
  transform-origin: 0% 100%;
  width: 12px;
}

.hamburger.active span {
  opacity: 1;
  background-color: white;
  transform: rotate(45deg) translate(-6px, -8px);
}

.hamburger.active span:nth-last-child(2) {
  opacity: 0;
  transform: none;
}

.hamburger.active span:nth-last-child(3) {
  transform: rotate(-45deg) translate(-8px, 10px);
}

.niche-parallax {
  transform: translate(var(--niche-pos-x), var(--niche-pos-y));
}

.tensor-parallax {
  transform: translate(var(--tensor-pos-x), var(--tensor-pos-y));
}

.hero-desc-opacity {
  opacity: var(--hero-desc-opacity);
}

.api-hero-parallax {
  transform: translateY(var(--hero-api-translateY));
}

.m-api-empty {
  top: 0;
}

.hero-arrow-opacity {
  opacity: var(--hero-arrow-opacity);
  rotate: var(--hero-arrow-rotate);
  cursor: pointer;
}

.section-hero {
  height: calc(100vh - 80px)
}

.m-api-full {
  height: calc(100vh - 60px);
}
@media (min-width: 1440px) {
  .section-hero {
    height: calc(100vh - 174px)
  }
}

@media (max-width: 767px) {
  .section-hero_charactor {
    margin-left: -80vw;
    margin-top: 38vh;
  }
}

.section-hero_charactor img {
  object-fit: cover;
  position: absolute;
  width: 229vw;
  height: auto;
  left: 70px;
  /* top: -100vh; */
  bottom: 0;
  max-width: unset;
}

@media (max-width: 768px) {
  .hero-img-wrapper {
    left: 0!important;
    margin-left: 0!important;
  }
}

@media (max-width: 768px) {
  .section-hero_charactor img {
    left: 50%;
    margin-left: 0;
    transform: translateX(-50%);
  }
}

@media (min-width: 768px) {
  .section-hero_charactor img {
    width: auto;
    height: 140vh;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1440px) {
  .hero-slogan {
    max-width: 550px;
    white-space: normal !important;
  }
}

@media (min-width: 1440px) {
  .section-hero_charactor img {
    bottom: -30vh;
  }
}

@media (min-width: 1920px) {
  .section-hero_charactor img {}
}

.arrow-icon {
  display: none;
}

@media (min-width: 768px) {
  .arrow-icon {
    display: block;
  }
}

.arrow-mobile-icon {
  display: block;
  transform: translateY(var(--hero-api-translateY));

}

@media (min-width: 768px) {
  .arrow-mobile-icon {
    display: none;
  }
}

.menu-overlay {
  background: #000;
  opacity: 0.8;
}

@media (min-width: 768px) {
  .border-right-gray {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.more-resources-link .arrow-container {
  position: relative;
  transition: all 0.3s ease;
}

.more-resources-link .arrow-img {
  position: absolute;
  top: 40px;
  right: 40px;
  transition: all 0.3s ease;
}

.more-resources-link:hover .arrow-img {
  top: 20px;
  right: 20px;
}

@media (min-width: 768px) {
  .hero-section-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 174px);
  }
}